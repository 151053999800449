var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: "LBL0000221" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "LBL0000211",
                    name: "workSummary",
                  },
                  model: {
                    value: _vm.workPermit.workSummary,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workSummary", $$v)
                    },
                    expression: "workPermit.workSummary",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-process", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "LBL0000222",
                    name: "processCd",
                  },
                  model: {
                    value: _vm.workPermit.processCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "processCd", $$v)
                    },
                    expression: "workPermit.processCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: !_vm.isWriting,
                    label: "LBL0000223",
                    name: "sopName",
                  },
                  model: {
                    value: _vm.workPermit.sopName,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "sopName", $$v)
                    },
                    expression: "workPermit.sopName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-vendor", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    isWrite: true,
                    data: _vm.workPermit,
                    label: "LBL0000224",
                    name: "vendorCd",
                  },
                  on: { dataChange: _vm.vendorChange },
                  model: {
                    value: _vm.workPermit.vendorCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "vendorCd", $$v)
                    },
                    expression: "workPermit.vendorCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-location", {
                  attrs: {
                    isMapShowing: "",
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.workPermit,
                    etcFlag: "Y",
                    etcInput: "locationEtc",
                    label: "작업장소",
                    name: "workLocation",
                  },
                  on: { dataChange: _vm.setLocationInfo },
                  model: {
                    value: _vm.workPermit.workLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workLocation", $$v)
                    },
                    expression: "workPermit.workLocation",
                  },
                }),
              ],
              1
            ),
            !_vm.isVendorReqeust
              ? _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-4" },
                  [
                    _c("c-equip", {
                      attrs: {
                        disabled: !_vm.isWriting,
                        editable: _vm.editable,
                        plantCd: _vm.workPermit.plantCd,
                        processCd: _vm.workPermit.processCd,
                        data: _vm.workPermit,
                        isWrite: true,
                        label: "LBL0000227",
                        name: "equipmentCd",
                      },
                      model: {
                        value: _vm.workPermit.equipmentCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.workPermit, "equipmentCd", $$v)
                        },
                        expression: "workPermit.equipmentCd",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isVendorReqeust
              ? _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        disabled: !_vm.isWriting,
                        editable: _vm.editable,
                        isFlag: true,
                        label: "LBL0000228",
                        name: "psmFlag",
                      },
                      model: {
                        value: _vm.workPermit.psmFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.workPermit, "psmFlag", $$v)
                        },
                        expression: "workPermit.psmFlag",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "LBL0000229",
                    name: "request",
                  },
                  model: {
                    value: _vm.request,
                    callback: function ($$v) {
                      _vm.request = $$v
                    },
                    expression: "request",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-field", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.workPermit,
                    plantCd: _vm.workPermit.plantCd,
                    deptValue: "workManagerDeptCd",
                    type: "dept_user",
                    userType: "lnf",
                    label: "LBLMANAGER",
                    beforeText: "",
                    name: "workManagerId",
                  },
                  model: {
                    value: _vm.workPermit.workManagerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workManagerId", $$v)
                    },
                    expression: "workPermit.workManagerId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    type: "number",
                    label: "작업자 인원수",
                    name: "workerCnt",
                  },
                  model: {
                    value: _vm.workPermit.workerCnt,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workerCnt", $$v)
                    },
                    expression: "workPermit.workerCnt",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-checkbox", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    codeGroupCd: "SOP_PROTECTIVE_GEAR_CD",
                    isObject: true,
                    valueText: "sopProtectiveGearName",
                    valueKey: "sopProtectiveGearCd",
                    label: "LBL0000231",
                    name: "protectiveGears",
                  },
                  model: {
                    value: _vm.workPermit.protectiveGears,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "protectiveGears", $$v)
                    },
                    expression: "workPermit.protectiveGears",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    rows: 2,
                    label: "기타요구사항",
                    name: "specialRequirements",
                  },
                  model: {
                    value: _vm.workPermit.specialRequirements,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "specialRequirements", $$v)
                    },
                    expression: "workPermit.specialRequirements",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
          [
            _c("c-table", {
              attrs: {
                title: "LBL0000232",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.workPermit.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm no-margin",
                    attrs: { title: "LBL0000241" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              deptValue: "issuedDeptCd",
                              type: "dept_user",
                              label: "LBL0000242",
                              beforeText: "",
                              name: "issuedUserId",
                            },
                            model: {
                              value: _vm.workPermit.issuedUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "issuedUserId", $$v)
                              },
                              expression: "workPermit.issuedUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              deptValue: "approvalDeptCd",
                              type: "dept_user",
                              label: "승인 (생산지원팀장)",
                              beforeText: "",
                              name: "approvalUserId",
                            },
                            model: {
                              value: _vm.workPermit.approvalUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "approvalUserId", $$v)
                              },
                              expression: "workPermit.approvalUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: _vm.confiFlag,
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              deptValue: "maintenanceDeptDrivingManagerDeptCd",
                              type: "dept_user",
                              label: "운전부서장",
                              name: "maintenanceDeptDrivingManagerId",
                            },
                            model: {
                              value:
                                _vm.workPermit.maintenanceDeptDrivingManagerId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workPermit,
                                  "maintenanceDeptDrivingManagerId",
                                  $$v
                                )
                              },
                              expression:
                                "workPermit.maintenanceDeptDrivingManagerId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm no-margin",
                    attrs: { title: "LBL0000247" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              deptValue: "maintenanceDeptCheckDeptCd",
                              type: "dept_user",
                              label: "LBL0000248",
                              name: "maintenanceDeptCheckUserId",
                            },
                            model: {
                              value: _vm.workPermit.maintenanceDeptCheckUserId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workPermit,
                                  "maintenanceDeptCheckUserId",
                                  $$v
                                )
                              },
                              expression:
                                "workPermit.maintenanceDeptCheckUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              deptValue: "maintenanceDeptEntryDeptCd",
                              type: "dept_user",
                              label: "담당자",
                              name: "maintenanceDeptEntryUserId",
                            },
                            model: {
                              value: _vm.workPermit.maintenanceDeptEntryUserId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workPermit,
                                  "maintenanceDeptEntryUserId",
                                  $$v
                                )
                              },
                              expression:
                                "workPermit.maintenanceDeptEntryUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              deptValue: "maintenanceDeptSafetyMnagerDeptCd",
                              type: "dept_user",
                              label: "안전관리자",
                              name: "maintenanceDeptSafetyManagerId",
                            },
                            model: {
                              value:
                                _vm.workPermit.maintenanceDeptSafetyManagerId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workPermit,
                                  "maintenanceDeptSafetyManagerId",
                                  $$v
                                )
                              },
                              expression:
                                "workPermit.maintenanceDeptSafetyManagerId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
              [
                _c(
                  "c-table",
                  {
                    ref: "gasTable",
                    attrs: {
                      title: "LBL0000236",
                      columns: _vm.grid2.columns,
                      gridHeight: _vm.grid2.height,
                      data: _vm.workPermit.gases,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      editable: _vm.editable && _vm.isWriting,
                      selection: "multiple",
                      rowKey: "sopGasCheckId",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && _vm.isWriting
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "LBL0000253",
                                    icon: "add",
                                    showLoading: false,
                                  },
                                  on: { btnClicked: _vm.addGas },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.isWriting
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "LBL0000275",
                                    icon: "remove",
                                    showLoading: false,
                                  },
                                  on: { btnClicked: _vm.removeGas },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }